import type { ActionContext } from 'vuex';
import {
	ActionTypes,
	PersonalData,
	PAYMENT_STATUS,
	SUBSCRIPTION,
	UserState,
	GetterTypes,
	EmailNotifications,
	PaymentChannel,
	User,
	TemplateImportOption,
} from '@pixcap/ui-core/models/store/user.interface';
import { mutationsWrapper as UserMutations, actionsWrapper as UserWrapper } from '@pixcap/ui-core/store/user/wrapper';
import { actionsWrapper as AuthWrapper } from '@pixcap/ui-core/store/auth/wrapper';
import { $notify } from '@pixcap/ui-core/helpers/notification';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { AVATAR_MIME_TYPE } from '@pixcap/ui-core/constants/user.constants';
import { PORTAL_PAYEMNT_REDIRECT_URL } from '@pixcap/ui-core/constants/payment.constants';
import { API_PATHS, MULTIPART_UPLOAD_TYPES } from '@pixcap/ui-core/constants/api.constants';
import { appUtilities } from '@pixcap/ui-core/modules/appUtilities';
import { v4 as uuidv4 } from 'uuid';
import { WEBSOCKET_OPERATIONS, RSocketCancelToken, WebSocketManager } from '@pixcap/ui-core/services/socketmanager';
import { AppState, NotificationType } from '@pixcap/ui-core/models/store/app.interface';
import { InvalidMultipartUploadError } from '@pixcap/ui-core/errors/InvalidMultipartUploadError';
import logger from '@pixcap/ui-core/helpers/logger';
import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
import { CART_ITEMS } from '@pixcap/ui-core/constants/auth.constants';
import { STRIPE_UNSUPPORTED_COUNTRIES } from '@pixcap/ui-core/constants/subscription.constants';
import { getCookie } from '@pixcap/ui-core/utils/WindowUtils';
import { SESSION_ID_COOKIE_KEY } from '@pixcap/ui-core/constants/app.constants';
import { NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;
const PAYMENTS_PATH = API_PATHS.PAYMENTS_PATH;
const NOTIFICATIONS_PATH = API_PATHS.NOTIFICATIONS_PATH;

let accountSubscriptionChangeCancelToken: RSocketCancelToken;
let creditsChangeCancelToken: RSocketCancelToken;
let notificationsCancelToken: RSocketCancelToken;
let sessionChangeCancelToken: RSocketCancelToken;

export default {
	async [ActionTypes.FETCH_USER_INFO](context: ActionContext<any, any>, showLoading = true) {
		const res = await httpClient.get(`${ASSETMANAGER_PATH}/user/activity`);
		if (!res) return null;
		const personalData: PersonalData = res.data;
		UserMutations.updatePersonalData(context, personalData);
		if (showLoading) UserMutations.setIsLoadingUserInfo(context, false);
		return res;
	},
	async [ActionTypes.FETCH_PERSONAL_DATA](context: ActionContext<any, any>, payload: { isForceRefresh?: boolean; ignoreSockets?: boolean }) {
		const { isForceRefresh = false, ignoreSockets = false } = payload;
		try {
			const userStore: UserState = context.state;
			if (!isForceRefresh && userStore.personalData != null)
				return { personalData: userStore.personalData, subscriptionInfo: userStore.subscriptionInfo };
			if (!ignoreSockets) {
				UserWrapper.subscribeAccountSubscriptionChange(context);
				UserWrapper.subscribeNotifications(context);
				UserWrapper.subscribeCreditsChange(context);
				UserWrapper.subscribeRewardsChange(context);
				UserWrapper.subscribeSessionChange(context);
			}
			const promises = [];
			const userInfoPromise = UserWrapper.fetchUserInfo(context);
			const referralPromise = UserWrapper.fetchReferralsInfo(context);
			const subscriptionPromise = httpClient.get(`${PAYMENTS_PATH}/subscription`);

			promises.push(userInfoPromise);
			promises.push(referralPromise);
			promises.push(subscriptionPromise);
			subscriptionPromise.then((res) => {
				if (!res) return;
				const { subscription, status, currentPeriodEnd, isFreeTrialInitiated, channel, pricingCountryCode, price } = res.data;
				UserMutations.setSubscriptionInfo(context, {
					subscription,
					price,
					paymentStatus: status,
					currentPeriodEnd,
					isFreeTrialInitiated,
					channel,
					pricingCountryCode,
				});
			});
			const responses = await Promise.all(promises);
			if (responses[0]?.data?.user) {
				const { email, userId } = responses[0]?.data?.user;
				appUtilities.$mixpanel?.identify(userId);
				appUtilities.$setUserForErrorTracker(email, userId);
			}

			if (responses) return { personalData: responses[0]?.data, subscriptionInfo: responses[1]?.data };
		} catch (err) { }
		return {};
	},
	async [ActionTypes.FETCH_SUBSCRIPTION](context: ActionContext<any, any>) {
		const response = await httpClient.get(`${PAYMENTS_PATH}/subscription`);
		if (response) {
			const { subscription, status, currentPeriodEnd, isFreeTrialInitiated, channel, pricingCountryCode, price } = response.data;
			// if user subscribed than increase the average time for video export
			if (subscription != SUBSCRIPTION.BASIC && status === PAYMENT_STATUS.ACTIVE)
				appUtilities.$services.serverRenderProgressSimulator.averageMinuteVideoExportDuration = 60;
			else appUtilities.$services.serverRenderProgressSimulator.averageMinuteVideoExportDuration = 120;
			UserMutations.setSubscriptionInfo(context, {
				subscription,
				price,
				paymentStatus: status,
				currentPeriodEnd,
				isFreeTrialInitiated,
				channel,
				pricingCountryCode,
			});
		}
	},
	async [ActionTypes.FETCH_REFERRALS_INFO](context: ActionContext<any, any>) {
		const response = await httpClient.get(`${PAYMENTS_PATH}/referrals`);
		if (response) {
			const { referralCode, rewards } = response.data;
			UserMutations.setReferralsInfo(context, {
				referralCode,
				rewards,
			});
		}
	},
	async [ActionTypes.FETCH_COUNTRY_CODE](context: ActionContext<any, any>) {
		const response = await httpClient.get(`${ASSETMANAGER_PATH}/user/countryCode`);
		if (response) {
			const { countryCode } = response.data;
			UserMutations.setUserCountry(context, countryCode);
		}
	},
	async [ActionTypes.FETCH_NOTIFICATIONS](context: ActionContext<any, any>) {
		const response = await httpClient.get(`${NOTIFICATIONS_PATH}/all`);
		if (response) {
			const { content, page, size, totalItems, totalPages } = response.data;
			UserMutations.setUserNotifications(context, {
				content,
				page,
				size,
				totalItems,
				totalPages,
			});
		}
	},
	async [ActionTypes.SUBCRIBE_NOTIFICATIONS](context: ActionContext<any, any>) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;

			const onReceiveCallback = async (payload) => {
				if (payload.message) {
					UserMutations.pushUserNotifications(context, JSON.parse(payload.message));
				}
			};

			if (notificationsCancelToken) {
				notificationsCancelToken.cancel();
				notificationsCancelToken = new RSocketCancelToken();
			} else {
				notificationsCancelToken = new RSocketCancelToken();
			}

			const recoveryCallback = async () => {
				logger.log('Reconnecting to notifications subscription stream socket');
				await webSocketManager.subscribeRequestStream<null, void>(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_NOTIFICATIONS_LIST,
					null,
					onReceiveCallback,
					recoveryCallback
				);
			};

			await webSocketManager.subscribeRequestStream(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_NOTIFICATIONS_LIST,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				notificationsCancelToken
			);
		} catch (err) {
			logger.error({ err }, 'Encounter error subscribing for user notifications');
		}
	},
	/**
	 * Intended to connect and stream indefinitely, i.e. promise will never resolve
	 */
	async [ActionTypes.SUBSCRIBE_USER_SESSIONS](context: ActionContext<any, any>) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;

			const onReceiveCallback = async (payload) => {
				const { sessionId, action } = JSON.parse(payload.message);
				if (action == 'logout') {
					try {
						const cookie = document.cookie;
						const currentSessionId = getCookie(cookie, SESSION_ID_COOKIE_KEY);
						if (currentSessionId == sessionId) {
							AuthWrapper.logout(context, { sessionChange: true });
						}
					} catch (err) { }
				}
			};

			const recoveryCallback = async () => {
				logger.log('Reconnecting to user session stream socket');
				await webSocketManager.subscribeRequestStream<null, void>(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_USER_SESSIONS,
					null,
					onReceiveCallback,
					recoveryCallback
				);
			};

			if (sessionChangeCancelToken) {
				sessionChangeCancelToken.cancel();
				sessionChangeCancelToken = new RSocketCancelToken();
			} else {
				sessionChangeCancelToken = new RSocketCancelToken();
			}

			await webSocketManager.subscribeRequestStream<null, void>(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_USER_SESSIONS,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				sessionChangeCancelToken
			);
		} catch (e) {
			logger.warn('Encounter error in user session stream', e);
		}
	},
	async [ActionTypes.SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE](context: ActionContext<UserState, any>) {
		const { state: userState } = context;
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			const onReceiveCallback = async () => {
				await UserWrapper.fetchSubscription(context);
				const { subscription, paymentStatus } = userState.subscriptionInfo;
				const isProSubscription =
					subscription === SUBSCRIPTION.PRO_ANNUALLY || subscription === SUBSCRIPTION.PRO_MONTHLY || subscription === SUBSCRIPTION.PRO_QUARTERLY;
				if (subscription != SUBSCRIPTION.BASIC && status === PAYMENT_STATUS.ACTIVE)
					appUtilities.$services.serverRenderProgressSimulator.averageMinuteVideoExportDuration = 60;
				else appUtilities.$services.serverRenderProgressSimulator.averageMinuteVideoExportDuration = 120;
				if (paymentStatus === PAYMENT_STATUS.FREE_TRIAL || paymentStatus === PAYMENT_STATUS.ACTIVE) {
					// fetch only if subscription passed , this used for AI variant sync
					await UserWrapper.fetchUserInfo(context, false);
					$notify({
						type: NotificationType.SUCCESS,
						title: 'Subscription upgraded',
						text: 'Thank you so much for supporting Pixcap!',
						duration: 3000,
					});
				} else {
					if (paymentStatus === PAYMENT_STATUS.CANCELLED) {
						$notify({
							type: NotificationType.SUCCESS,
							title: 'Subscription cancelled',
							text: `Your Pixcap ${isProSubscription ? 'Pro' : 'Elite'} subscription has been cancelled.`,
							duration: 3000,
						});
					} else if (paymentStatus === PAYMENT_STATUS.FREE_TRIAL_CANCELLED) {
						$notify({
							type: NotificationType.SUCCESS,
							title: 'Subscription cancelled',
							text: 'Your Pixcap Pro free trial renewal has been cancelled.',
							duration: 3000,
						});
					} else if (paymentStatus === PAYMENT_STATUS.PENDING_RENEWAL) {
						$notify({
							type: NotificationType.WARN,
							title: 'Subscription expired',
							text: 'Your Pixcap subscription has expired. Please choose a plan to renew.',
							duration: 3000,
						});
					} else if ([PAYMENT_STATUS.PAYMENT_ACTION_REQUIRED, PAYMENT_STATUS.PAYMENT_REQUIRES_METHOD].includes(paymentStatus)) {
						$notify({
							type: NotificationType.WARN,
							title: 'Subscription paused',
							text: `There was a problem with payment for your Pixcap subscription. Please retry payment to continue enjoying your ${isProSubscription ? 'Pro' : 'Elite'
								} subscription.`,
							duration: 3000,
						});
					}
				}
				appUtilities.$mixpanel?.track('Changed Subscription', { Subscription: subscription });
			};

			const recoveryCallback = async () => {
				logger.log('Reconnecting to account subscription stream socket');
				await webSocketManager.subscribeRequestStream<null, void>(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE,
					null,
					onReceiveCallback,
					recoveryCallback
				);
			};

			if (accountSubscriptionChangeCancelToken) {
				accountSubscriptionChangeCancelToken.cancel();
				accountSubscriptionChangeCancelToken = new RSocketCancelToken();
			} else {
				accountSubscriptionChangeCancelToken = new RSocketCancelToken();
			}

			await webSocketManager.subscribeRequestStream<null, void>(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_ACCOUNT_SUBSCRIPTION_CHANGE,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				accountSubscriptionChangeCancelToken
			);
		} catch (e) {
			logger.warn('Encounter error in account subscription stream', e);
		}
	},
	async [ActionTypes.SUBSCRIBE_CREDITS_CHANGE](context: ActionContext<UserState, any>) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			const onReceiveCallback = async () => {
				await UserWrapper.fetchUserInfo(context);
			};

			const recoveryCallback = async () => {
				logger.log('Reconnecting to credits stream socket');
				await webSocketManager.subscribeRequestStream<null, void>(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_CREDITS_CHANGE,
					null,
					onReceiveCallback,
					recoveryCallback
				);
			};

			if (creditsChangeCancelToken) {
				creditsChangeCancelToken.cancel();
				creditsChangeCancelToken = new RSocketCancelToken();
			} else {
				creditsChangeCancelToken = new RSocketCancelToken();
			}

			await webSocketManager.subscribeRequestStream<null, void>(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_CREDITS_CHANGE,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				creditsChangeCancelToken
			);
		} catch (e) {
			logger.warn('Encounter error in account subscription stream', e);
		}
	},
	async [ActionTypes.SUBSCRIBE_REWARDS_CHANGE](context: ActionContext<UserState, any>) {
		try {
			const webSocketManager = WebSocketManager.lastInitSocketManager;
			const onReceiveCallback = async () => {
				await UserWrapper.fetchReferralsInfo(context);
			};

			const recoveryCallback = async () => {
				logger.log('Reconnecting to credits stream socket');
				await webSocketManager.subscribeRequestStream<null, void>(
					WEBSOCKET_OPERATIONS.SUBSCRIBE_REWARDS_CHANGE,
					null,
					onReceiveCallback,
					recoveryCallback
				);
			};

			if (creditsChangeCancelToken) {
				creditsChangeCancelToken.cancel();
				creditsChangeCancelToken = new RSocketCancelToken();
			} else {
				creditsChangeCancelToken = new RSocketCancelToken();
			}

			await webSocketManager.subscribeRequestStream<null, void>(
				WEBSOCKET_OPERATIONS.SUBSCRIBE_REWARDS_CHANGE,
				null,
				onReceiveCallback,
				recoveryCallback,
				null,
				creditsChangeCancelToken
			);
		} catch (e) {
			logger.warn('Encounter error in account subscription stream', e);
		}
	},
	async [ActionTypes.CHANGE_AVATAR](context: ActionContext<any, any>, avatarBlob: any) {
		try {
			const key = uuidv4();
			const partSize = 10000000; // 10MB
			const uploadType = MULTIPART_UPLOAD_TYPES.AVATAR;
			const contentType = AVATAR_MIME_TYPE;
			await appUtilities.$services.multiUploadService.multipartUpload(avatarBlob, uploadType, { key, partSize, contentType });
			const response = await httpClient.post(`${ASSETMANAGER_PATH}/multipart/avatar`, { key, uploadType });
			if (response.data) {
				UserMutations.updateUserAvatar(context, response.data);
			}
		} catch (err) {
			if (err instanceof InvalidMultipartUploadError) {
				$notify({
					type: NotificationType.ERROR,
					title: 'File does not exist. Please check file name at location is correct and try again.',
				});
			} else {
				$notify({
					type: NotificationType.ERROR,
					title: 'Failed image upload. Please try again.',
				});
			}
		}
	},
	async [ActionTypes.FETCH_TUTORIALS](context: ActionContext<any, any>) {
		const response = await httpClient.get(`${ASSETMANAGER_PATH}/tutorial`);
		return response.data;
	},
	async [ActionTypes.UPDATE_PERSONAL_DATA](
		context: ActionContext<any, any>,
		updateDto: {
			bio?: string;
			displayName?: string;
			showPixrenderPopup?: boolean;
			onboarding?: User['onboarding'];
			aiAttempted?: boolean;
			openProject?: TemplateImportOption;
		}
	) {
		const transformedDto = { ...updateDto };
		const onboarding = updateDto.onboarding;
		delete transformedDto.onboarding;
		for (const key in onboarding) {
			transformedDto[`${key}Onboarded`] = onboarding[key];
		}
		await httpClient.put(`${ASSETMANAGER_PATH}/user`, transformedDto);
		if (updateDto.bio) UserMutations.updateBio(context, updateDto.bio);
		if (updateDto.displayName) UserMutations.updateDisplayName(context, updateDto.displayName);
		if (updateDto.showPixrenderPopup) UserMutations.updateShowPixrenderPopup(context, updateDto.showPixrenderPopup);
		if (updateDto.onboarding) UserMutations.updateUserOnboarding(context, updateDto.onboarding);
		if (updateDto.aiAttempted != null) UserMutations.updateUserAiAttempted(context, updateDto.aiAttempted);
		if (updateDto.openProject != null) UserMutations.updateImportTemplateOption(context, updateDto.openProject);
	},
	async [ActionTypes.RENEW_SUBSCRIPTION](context: ActionContext<any, any>) {
		try {
			const userState: UserState = context.state;
			const paymentStatus = userState.subscriptionInfo.paymentStatus;
			await httpClient.put(`${PAYMENTS_PATH}/subscription/renew`);
			if (paymentStatus === PAYMENT_STATUS.FREE_TRIAL_CANCELLED) UserMutations.updatePaymentStatus(context, PAYMENT_STATUS.FREE_TRIAL);
			else UserMutations.updatePaymentStatus(context, PAYMENT_STATUS.ACTIVE);
		} catch {
			logger.error('Failed to renew subscription');
		}
	},
	async [ActionTypes.CANCEL_SUBSCRIPTION](context: ActionContext<any, any>, reason: string) {
		try {
			const userState: UserState = context.state;
			const currentSubscription = userState.subscriptionInfo.subscription;
			appUtilities.$mixpanel?.track('Cancel Subscription', { Subscription: currentSubscription, Reason: reason });
			await httpClient.put(`${PAYMENTS_PATH}/subscription/cancel`);
		} catch (e) {
			$notify({
				type: NotificationType.ERROR,
				title: 'Encountered problem while cancelling plan. Please try again',
				duration: 3000,
			});
		}
	},
	async [ActionTypes.ADD_CANCEL_SUBSCRIPTION_REASON](context: ActionContext<any, any>, reason: string) {
		const userState: UserState = context.state;
		const currentSubscription = userState.subscriptionInfo.subscription;
		appUtilities.$mixpanel?.track('Cancel Subscription', { Subscription: currentSubscription, Reason: reason });
		await httpClient.post(`${PAYMENTS_PATH}/cancellation/add`, { reason });
	},
	async [ActionTypes.FETCH_SUBSCRIPTION_PAYMENT_PORTAL](
		context: ActionContext<any, any>,
		payload: {
			newSubscription: SUBSCRIPTION;
			channel?: PaymentChannel;
			openPaymentMethodModal?: boolean;
			redirectUrl?: string;
			promotionCode?: string;
		}
	) {
		try {
			const {
				newSubscription,
				channel = PaymentChannel.STRIPE,
				openPaymentMethodModal = false,
				redirectUrl = `${_PIXCAP_ENV.ROOT_URL}${PORTAL_PAYEMNT_REDIRECT_URL}`,
				promotionCode,
			} = payload;
			const userState: UserState = context.state;
			const locale = (context.rootState[`${APP_NAMESPACE}`] as AppState).locale;
			const { subscription, channel: existingChannel } = userState.subscriptionInfo || {};
			const isFreeTrialAvailable = context.getters[GetterTypes.IS_FREE_TRIAL_AVAILABLE];
			if (openPaymentMethodModal) {
				UserMutations.setUpgradeModalState(context, null);
				UserMutations.setSelectedSubscriptionForPayment(context, newSubscription);
				return;
			}
			let portalUrl;
			let params;
			if (isFreeTrialAvailable) {
				portalUrl = `${PAYMENTS_PATH}/checkout/portal/subscription/${newSubscription}`;
				params = {
					freeTrial: true,
					channel,
					redirectUrl: redirectUrl,
					timestamp: Date.now(),
					lang: locale,
				};
				appUtilities.$mixpanel?.track('Open Stripe Checkout', { Subscription: newSubscription });
			} else {
				if (subscription !== SUBSCRIPTION.BASIC && existingChannel == 'STRIPE' && channel == PaymentChannel.STRIPE) {
					portalUrl = `${PAYMENTS_PATH}/customer/portal?redirectUrl=${redirectUrl}`;
					params = {
						timestamp: Date.now(),
					};
				} else {
					portalUrl = `${PAYMENTS_PATH}/checkout/portal/subscription/${newSubscription}`;
					params = {
						freeTrial: false,
						coupon: promotionCode ? promotionCode : null,
						channel,
						redirectUrl: redirectUrl,
						timestamp: Date.now(),
						lang: locale,
					};
				}

				appUtilities.$mixpanel?.track('Open Stripe Checkout', { Subscription: newSubscription });
			}

			const response = await httpClient.get(portalUrl, {
				params,
			});
			if (response.data) return response.data;
		} catch {
			return null;
		}
	},
	async [ActionTypes.FETCH_CUSTOMER_PORTAL](context: ActionContext<any, any>) {
		const redirectUrl = `${_PIXCAP_ENV.ROOT_URL}${PORTAL_PAYEMNT_REDIRECT_URL}?redirect_origin=${window.location.href}`;
		const response = await httpClient.get(`${PAYMENTS_PATH}/customer/portal`, {
			params: {
				timestamp: Date.now(),
				redirectUrl,
			},
		});
		if (response.data) return response.data;
		return null;
	},
	async [ActionTypes.FETCH_INVOICES_PORTAL](context: ActionContext<any, any>) {
		try {
			const redirectUrl = `${_PIXCAP_ENV.ROOT_URL}${PORTAL_PAYEMNT_REDIRECT_URL}?redirect_origin=${window.location.href}`;
			const response = await httpClient.get(`${PAYMENTS_PATH}/customer/portal/invoice`, {
				params: {
					timestamp: Date.now(),
					redirectUrl,
				},
			});
			if (response.data) return response.data;
		} catch {
			return null;
		}
	},
	async [ActionTypes.CONTACT_US](
		context: ActionContext<any, any>,
		payload: { name: string; email: string; message: string; isCustomPricing?: boolean; concern?: string }
	) {
		const { name, email, message, isCustomPricing = false, concern } = payload;
		UserMutations.setIsSendingMessage(context, true);
		try {
			const response = await httpClient.post(`${ASSETMANAGER_PATH}/contact/contactUs`, {
				name,
				email,
				messageBody: message,
				isCustomPricing,
				concern,
			});
			if (response.data) {
				$notify({
					type: NotificationType.SUCCESS,
					title: 'We have received your request!',
					text: "We'll handle your request soon but it might take some time, just hold on tight and our team will handle it",
					duration: 3000,
				});
				UserMutations.setShowContactModal(context, null);
			}
		} catch {
			$notify({ type: NotificationType.ERROR, title: 'Failed to send your message please try again' });
		} finally {
			UserMutations.setIsSendingMessage(context, false);
		}
	},
	async [ActionTypes.FETCH_EMAIL_NOTIFICATIONS](context: ActionContext<any, any>) {
		try {
			const response = await httpClient.get(NOTIFICATIONS_PATH);
			UserMutations.setEmailNotifications(context, response.data);
		} catch (err) {
			$notify({ type: NotificationType.ERROR, title: 'Failed to fetch your email settings, please try again' });
		}
	},
	async [ActionTypes.UPDATE_EMAIL_NOTIFICATION](context: ActionContext<any, any>, payload: EmailNotifications) {
		try {
			const response = await httpClient.put(`${NOTIFICATIONS_PATH}`, payload);
			if (!response?.data) return { isSuccess: false };

			$notify({
				type: NotificationType.SUCCESS,
				title: 'Notifications were updated!',
				duration: 3000,
			});
			UserMutations.setEmailNotifications(context, payload);
			return { isSuccess: true };
		} catch (err) {
			$notify({ type: NotificationType.ERROR, title: 'Failed to update your settings, please try again' });
			return { isSuccess: false };
		}
	},
	async [ActionTypes.FETCH_TOP_UP_CREDITS_PORTAL](
		context: ActionContext<any, any>,
		payload: { quantity: number; channel?: string; openPaymentMethodModal?: boolean }
	) {
		try {
			const { quantity, channel = PaymentChannel.STRIPE, openPaymentMethodModal = false } = payload;
			const locale = (context.rootState[`${APP_NAMESPACE}`] as AppState).locale;
			if (openPaymentMethodModal) {
				UserMutations.setTopUpVideoLimitsModalState(context, null);
				UserMutations.setToppedCreditsQuantityForPayment(context, quantity);
				return;
			}

			const redirectUrl = `${_PIXCAP_ENV.ROOT_URL}${PORTAL_PAYEMNT_REDIRECT_URL}?redirect_origin=${window.location.href}`;
			const response = await httpClient.get(`${PAYMENTS_PATH}/checkout/portal/topped/video/${quantity}`, {
				params: {
					channel,
					redirectUrl,
					lang: locale,
				},
			});
			if (response.data) return response.data;
		} catch {
			return null;
		}
	},

	async [ActionTypes.FETCH_CART_CHECKOUT_PORTAL](
		context: ActionContext<any, any>,
		payload: { itemIds?: string[]; packIds?: string[]; channel?: string }
	) {
		try {
			const overridingPricingCountryCode = context.getters[GetterTypes.OVERRIDING_PRICING_COUNTRY_CODE];
			const { CURRENCY_CODE } = context.getters[GetterTypes.GET_PRICING_FOR_USER];
			const locale = (context.rootState[`${APP_NAMESPACE}`] as AppState).locale;
			let currencyCode = CURRENCY_CODE;
			if (STRIPE_UNSUPPORTED_COUNTRIES.includes(overridingPricingCountryCode)) currencyCode = 'USD';
			const { itemIds, packIds, channel = PaymentChannel.STRIPE } = payload;

			const redirectUrl = `${_PIXCAP_ENV.ROOT_URL}${PORTAL_PAYEMNT_REDIRECT_URL}?redirect_origin=${window.location.href}`;
			const response = await httpClient.put(
				`${PAYMENTS_PATH}/checkout/portal/cart?redirectUrl=${redirectUrl}&channel=${channel}&currencyCode=${currencyCode}`,
				{
					itemIds: itemIds.length > 0 ? itemIds : [],
					packIds: packIds.length > 0 ? packIds : [],
					lang: locale,
				}
			);
			if (response.data) return response.data;
		} catch {
			return null;
		}
	},

	async [ActionTypes.FETCH_CART_ITEMS](
		context: ActionContext<any, any>,
		payload: { manuallyFetch?: boolean; itemIds?: string[]; packIds?: string[] }
	) {
		const overridingPricingCountryCode = context.getters[GetterTypes.OVERRIDING_PRICING_COUNTRY_CODE];
		const { CURRENCY_CODE } = context.getters[GetterTypes.GET_PRICING_FOR_USER];
		let currencyCode = CURRENCY_CODE;
		if (STRIPE_UNSUPPORTED_COUNTRIES.includes(overridingPricingCountryCode)) currencyCode = 'USD';

		const { manuallyFetch = false, itemIds, packIds } = payload;
		try {
			if (!manuallyFetch) UserMutations.setIsFetchingCartItems(context, true);
			const response = await httpClient.put(`${ASSETMANAGER_PATH}/library/pixcap/cart?currencyCode=${currencyCode ? currencyCode : 'USD'}`, {
				itemIds,
				packIds,
			});
			if (response.data) {
				if (!manuallyFetch) {
					UserMutations.setCartItems(context, response.data);
					return;
				}
				return response.data;
			}
		} finally {
			if (!manuallyFetch) UserMutations.setIsFetchingCartItems(context, false);
		}
	},

	async [ActionTypes.FETCH_PURCHASED_ITEMS](
		context: ActionContext<any, any>,
		payload: {
			refresh?: boolean;
			page: number;
			pageSize?: number;
			search?: string;
			sortBy?: string;
		}
	) {
		try {
			const { refresh, page, pageSize = 20, search, sortBy } = payload;
			const params = { pageSize, page, search, sortBy };
			UserMutations.setIsFetchingPurchaseItems(context, true);
			if (refresh) UserMutations.resetPurchaseItems(context);
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/purchaseHistory/detail`, { params });
			if (response.data && response.data.content) {
				const { content, totalItems, totalPages } = response.data;
				UserMutations.setPurchasedItems(context, content, totalPages, content.length ? page + 1 : page, refresh);
			}
		} finally {
			UserMutations.setIsFetchingPurchaseItems(context, false);
		}
	},
	async [ActionTypes.FETCH_PRESIGNED_PURCHASED_ITEM](context: ActionContext<any, any>, itemId: string) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/presigned/purchaseHistory/${itemId}`);
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting presigned purchased item', e);
		}
	},
};
